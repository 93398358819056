import { LeadsContextProvider } from "../services/leadsContext";
import Leads from "./Leads";

const index = () => {
  return (
    <LeadsContextProvider>
      <Leads />
    </LeadsContextProvider>
  );
};

export default index;
