import {
  DatePicker,
  Flex,
  Form,
  FormProps,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
} from "antd";
import {
  BranchSelect,
  Froala,
  PhoneNumberInput,
  Upload,
} from "components/form";
import { IOption } from "components/form/select/Select";
import "flag-icons/css/flag-icons.min.css";
import CitySelect from "components/form/citySelect/CitySelect";

interface IField {
  title?: string;
  name: string;
  nameInput?: string;
  nameSelect?: string;
  type:
    | "image"
    | "input"
    | "number-input"
    | "number-input-with-select"
    | "select"
    | "select-city"
    | "date"
    | "textarea"
    | "format-textarea"
    | "phone-number"
    | "select-branch"
    | "radio";
  required?: boolean;
  options?: IOption[];
  language?: boolean;
  form: FormProps["form"];
  // [key: string]: unknown;
}

const Field = ({
  title,
  name,
  nameInput,
  nameSelect,
  type,
  required = false,
  options,
  language = false,
  form,
  ...props
}: IField) => {
  const languages = ["uz", "ru", "en"];
  const renderValue = () => {
    switch (type) {
      case "input":
        return language ? (
          <Form.Item label={title}>
            <Space direction="vertical" style={{ width: "100%" }}>
              {languages?.map((item) => (
                <Form.Item name={[name, item]} rules={[{ required }]} noStyle>
                  <Input
                    prefix={
                      <span
                        className={`fi fi-${item !== "en" ? item : "gb"}`}
                        style={{ borderRadius: "2px" }}
                      />
                    }
                  />
                </Form.Item>
              ))}
            </Space>
          </Form.Item>
        ) : (
          <Form.Item name={name} rules={[{ required }]} label={title}>
            <Input />
          </Form.Item>
        );
      case "image":
        return language ? (
          <Form.Item label={title}>
            <Space direction="vertical" style={{ width: "100%" }}>
              {languages?.map((item) => (
                <Form.Item name={[name, item]} rules={[{ required }]} noStyle>
                  <Upload form={form} name={[name, item]}>
                    {item}: {title}
                  </Upload>
                </Form.Item>
              ))}
            </Space>
          </Form.Item>
        ) : (
          <Form.Item name={name} rules={[{ required }]} label={title}>
            <Upload form={form} name={name as string}>
              {title}
            </Upload>
          </Form.Item>
        );
      case "date":
        return (
          <Form.Item name={name} rules={[{ required }]} label={title}>
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        );
      case "select":
        return (
          <Form.Item name={name} rules={[{ required }]} label={title}>
            <Select options={options} placeholder="Tanlang" />
          </Form.Item>
        );
      case "select-city":
        return (
          <CitySelect
            form={form}
            name={name}
            label={title}
            rules={[{ required }]}
          />
        );
      case "number-input":
        return (
          <Form.Item name={name} rules={[{ required }]} label={title}>
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        );
      case "number-input-with-select":
        return (
          <Space.Compact style={{ width: "100%" }}>
            <Form.Item
              name={nameInput?.split(".")}
              rules={[{ required }]}
              label={title}
              style={{ flexGrow: 1 }}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name={nameSelect?.split(".")}
              rules={[{ required }]}
              label="Turi"
            >
              <Select options={options} defaultValue={options?.[0]?.value} />
            </Form.Item>
          </Space.Compact>
        );
      case "textarea":
        return (
          <Form.Item label={title}>
            <Space direction="vertical" style={{ width: "100%" }}>
              {languages?.map((item) => (
                <Form.Item name={[name, item]} rules={[{ required }]} noStyle>
                  <Input.TextArea placeholder={item} />
                </Form.Item>
              ))}
            </Space>
          </Form.Item>
        );
      case "format-textarea":
        return language ? (
          <Form.Item label={title}>
            <Space direction="vertical" style={{ width: "100%" }}>
              {languages?.map((item) => (
                <Form.Item name={[name, item]} rules={[{ required }]} noStyle>
                  <Froala form={form} name={[name, item]} />
                </Form.Item>
              ))}
            </Space>
          </Form.Item>
        ) : (
          <Form.Item name={name} rules={[{ required }]} noStyle>
            <Froala form={form} name={name} />
          </Form.Item>
        );
      case "phone-number":
        return (
          <Form.Item name={name} label={title} rules={[{ required }]}>
            <PhoneNumberInput form={form} name={name} />
          </Form.Item>
        );
      case "select-branch":
        return (
          <BranchSelect
            form={form}
            name={name}
            label={title}
            rules={[{ required }]}
          />
        );
      case "radio":
        return (
          <Form.Item name={name} label={title} rules={[{ required }]}>
            <Radio.Group options={options} />
          </Form.Item>
        );
      default:
        return "";
    }
  };
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {/* {title && <Typography.Text strong>{title}:</Typography.Text>} */}
      {renderValue()}
    </Space>
  );
};

export default Field;
