import { TableProps } from "antd";
import api from "common/api";
import { IContact } from "common/types/common";
import { IField } from "components/ui/filter/container/Filter";
import useQueryParams from "hooks/useQueryParams";
import moment from "moment";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

interface DataType extends IContact {
  key: number;
}

export interface ILeadsFilter {
  search: string;
}

const Context = () => {
  const { t } = useTranslation();
  const { getAllParams } = useQueryParams();
  const [data, setData] = useState<IContact[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();

  const filterFields: IField[] = [
    {
      type: "search",
      name: "search",
    },
  ];

  const columns: TableProps<DataType>["columns"] = useMemo(
    () => [
      {
        title: "Ism",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Telefon raqam",
        dataIndex: "phone_number",
        key: "phone_number",
        render: (value) => <a href={`tel:${"+" + value}`}>+{value}</a>,
      },
      {
        title: "Dastur turi",
        dataIndex: "program_type",
        key: "program_type",
        render: (value) => t(`constants.degrees.${value}`),
      },
      {
        title: "Yaratilgan sana",
        dataIndex: "created_at",
        key: "created_at",
        render: (value) => moment(value).format("MM/D/YYYY, HH:mm:ss"),
      },
    ],
    [i18n.language]
  );

  const getPaging = async () => {
    try {
      setLoading(true);
      const response = await api.common.contactGetPaging(getAllParams);
      setData(response.data.data);
      setTotal(response.data.total);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPaging();
  }, [getAllParams]);

  return {
    state: { filterFields, columns, data, loading, total },
    actions: {},
  };
};

const LeadsContext = createContext<any>({ state: {}, actions: {} });

export const LeadsContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <LeadsContext.Provider value={value}>{children}</LeadsContext.Provider>
  );
};

export default function useLeadsContext() {
  return useContext<ReturnType<typeof Context>>(LeadsContext);
}
