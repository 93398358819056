import { Container, Filter, Table } from "components/ui";
import useLeadsContext from "../services/leadsContext";

const Leads = () => {
  const {
    state: { filterFields, columns, data, loading, total },
  } = useLeadsContext();

  return (
    <div>
      <Filter<any>
        filterFields={filterFields}
        onFinish={(values) => {
          console.log(values);
        }}
      />
      <Container padding={false} filter>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          total={total}
        />
      </Container>
    </div>
  );
};

export default Leads;
