import {
  ApiPaging,
  ApiResponse,
  IBranch,
  ICity,
  IContact,
  IState,
  paramApi,
} from "../../types/common";
import { Api } from "../api";

export class CommonApi extends Api {
  async cityGetAll() {
    return await this.execute<ApiResponse<ICity[]>>(
      "get",
      "city/get-all",
      null,
      null,
      undefined
    );
  }

  async branchGetAll() {
    return await this.execute<ApiResponse<IBranch[]>>(
      "get",
      "organization/get-all",
      null,
      null,
      undefined
    );
  }

  async stateGetAll() {
    return await this.execute<ApiResponse<IState[]>>(
      "get",
      "state/get-all",
      null,
      null,
      undefined
    );
  }

  async contactGetPaging(data: paramApi) {
    return await this.execute<ApiResponse<ApiPaging<IContact[]>>>(
      "post",
      "contact/paging",
      data,
      null,
      undefined
    );
  }
}
